
*::before,
*::after {
  box-sizing: border-box;
}
.jumbotron {
    background: linear-gradient(#1355dbd1,#11409e), url(../public/img/Institute/header.jpg), no-repeat center center;
    background-size: cover;
  }

  .jumbotron_about {
    background: linear-gradient(#11409e47, #11409e), url(../public/img/baner.png), no-repeat center center;
    background-size: cover;
    
  }
 
 
  
  .jumbotron.page-header {
    background: linear-gradient(rgba(40, 120, 235, 0.9), rgba(40, 120, 235, 0.9)), url(../public/img/Institute/course_header.jpg), no-repeat center center;
    background-size: cover;
  }

  .overlay-top::before,
  .overlay-bottom::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 85px;
    left: 0;
    z-index: 1;
  }
  
  .overlay-top::before {
    top: 0;
    background: url(../public/img/overlay-top.png) top center no-repeat;
    background-size: contain;
  }
  
  .overlay-bottom::after {
    bottom: 0;
    background: url(../public/img/overlay-bottom.png) bottom center no-repeat;
    background-size: contain;
  }

  .bg-image {
    background: linear-gradient(rgba(40, 120, 235, 0.05), rgba(40, 120, 235, 0.05)), url(../public/img/bg-image.jpg);
    background-attachment: fixed;
  }

  .section-titles h6::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    border-top: 2px dashed #F14D5D;
  }
  .btn-icon {
    width: 77px;
    height: 77px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .mr-4, .mx-4 {
    margin-right: 1.5rem !important;
}

.courses-item img {
  position: relative;
  margin-top: 40px;
  transition: .5s;
}

.courses-list-item img {
  position: relative;
  transition: .5s;
}

.courses-item .courses-text,
.courses-list-item .courses-text {
  position: absolute;
  width: 100%;
  height: calc(100% + 86px);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(to bottom, rgba(18, 15, 45, 0), #120f2d);
  transition: .5s;
  z-index: 1;
}

.courses-list-item .courses-text {
  height: 100%;
}

.courses-item:hover img {
  margin-top: 0px;
}

.courses-list-item:hover img {
  transform: scale(1.2);
}

.courses-item:hover .courses-text {
  height: calc(100% + 40px);
}



.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-fluid-hotel {
  max-width: 100%;
  height: auto;
}

.img-fluids {
  height: 65vh;
  width: 100%;
}

@media (max-width: 768px) { /* Adjust this breakpoint as needed */
  .img-fluids {
    height: 25vh; /* Allow the height to adjust automatically */
    width: 100%; /* Ensure the width fills the container */
   
  }}
  @media (max-width: 667px) { /* Adjust this breakpoint as needed */
    .img-fluid {
      height: 45vh; /* Allow the height to adjust automatically */
      width: 100%; /* Ensure the width fills the container */
     
    }

}

@media (max-width: 1024px) { 
  .img-fluids {
    height: 20vh; 
    width: 100%; 
   
  }

}

/* list group */

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: false;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #6B6A75;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
/* .... */

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #2878EB;
  border-color: #2878EB;
}

.course_detail_list{
  margin-right: 20px;
}

.badge-primary {
  color: #fff;
  background-color: #2878EB;
}





.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}


.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}


.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
  
select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.team-carousel-institute .owl-nav,
.related-carousel .owl-nav {
  position: absolute;
  width: 100%;
  height: 60px;
  top: calc(50% - 30px);
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  
}

.team-carousel-institute .owl-nav .owl-prev,
.team-carousel-institute .owl-nav .owl-next,
.related-carousel .owl-nav .owl-prev,
.related-carousel .owl-nav .owl-next {
  background: #2878EB;
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #2878EB;
  font-size: 22px;
  transition: .4s;
}

/* .team-carousel-institute .owl-nav .owl-prev:hover,
.team-carousel-institute .owl-nav .owl-next:hover,
.related-carousel-institute .owl-nav .owl-prev:hover,
.related-carousel-institute .owl-nav .owl-next:hover {
  background: #F14D5D;
} */

.testimonial-carousel .owl-item img {
  width: 80px;
  height: 80px;
}

.testimonial-carousel .owl-nav {
  position: absolute;
  /* width: 120px; */
  color: #FFFFFF;
  height: 60px;
 
  /* top: 0; 
   right: 0; */
  display: flex;
  z-index: 1; 
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: #2878EB;
  font-size: 22px;
  transition: .5s;
}

/* .testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next:hover {
  background: #F14D5D
}

.testimonial-carousel .owl-nav .owl-prev:hover {
  background: #2878EB;
} */

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #6B6A75;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}


 /* Styling for the map container  */
#mapContainer {
  width: 100%;
  height: 400px;
  margin-top: 3rem;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(196, 157, 157, 0.2);
}

/* / Additional styling for iframe inside map container / */
#mapContainer iframe {
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 10px;
  transition: transform 0.3s;
}

/* .owl-prev,
.owl-next {
    background-color: #2878EB !important;
}

.owl-next:hover {
    background-color:#F14D5D !important;
}
.owl-prev:hover {
  background-color: #F14D5D !important;
} */


.top_header_menu{
  background: #000e93;
}
.newsletter{
 background-color: #11409e;
}



/*** Service ***/
 .service-item-it {
  position: relative;
  height: 350px;
  padding: 30px 25px;
  background: #FFFFFF;
  box-shadow: 0 0 45px rgba(0, 0, 0, .08);
  transition: .5s;
}

.service-item-it:hover {
  background: var(--primary);
}

.service-item-it .service-icon {
  margin: 0 auto 20px auto;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  background: url(../public/img/It/icon-shape-primary.png) center center no-repeat;
  transition: .5s;
}

.service-item-it:hover .service-icon {
  color: var(--primary);
  background: url(../public/img/It/icon-shape-white.png);
}

.service-item-it h5,
.service-item-it p {
  transition: .5s;
}

.service-item-it:hover h5,
.service-item-it:hover p {
  color: var(--light);
}

.service-item-it a.btn {
  position: relative;
  display: flex;
  color: var(--primary);
  transition: .5s;
  z-index: 1;
}

.service-item-it:hover a.btn {
  color: var(--primary);
}

.service-item-it a.btn::before {
  position: absolute;
  content: "";
  width: 35px;
  height: 35px;
  top: 0;
  left: 0;
  border-radius: 35px;
  /* background: #DDDDDD; */
  transition: .5s;
  z-index: -1;
}

.service-item-it:hover a.btn::before {
  width: 100%;
  background: var(--light);
} 

/*** Project Portfolio ***/

#portfolio-flters .btn {
  position: relative;
  display: inline-block;
  margin: 10px 4px 0 4px;
  transition: .5s;
}

#portfolio-flters .btn::after {
  position: absolute;
  content: "";
  right: -1px;
  bottom: -1px;
  border-left: 20px solid transparent;
  border-right: 0 solid transparent;
  border-bottom: 50px solid #FFFFFF;
}

#portfolio-flters .btn:hover,
#portfolio-flters .btn.active {
  color: var(--light);
  background: var(--primary);
}

.portfolio-overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 30px;
  top: 0;
  left: 0;
  /* background: var(--primary); */
  
  background:linear-gradient(to bottom, rgba(18, 15, 45, 0), #120f2d);
   
  
  transition: .5s;
  z-index: 1;
  opacity: 0;
}

.portfolio-item:hover .portfolio-overlay {
  opacity: 1;
}



.portfolio-item:hover .btn {
  opacity: 1;
  transition-delay: .15s;
}


/*** Testimonial ***/

.testimonial-it {
    background:
        url(../public/img/It/bg-top.png),
        url(../public/img/It/bg-bottom.png);
    background-position:
        left top,
        right bottom;
    background-repeat: no-repeat;
}

.testimonial-carousel .owl-item .testimonial-item,
.testimonial-carousel .owl-item.center .testimonial-item * {
    transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
    background: var(--light) !important;
    border-color: var(--light);
}

.testimonial-carousel .owl-item.center .testimonial-item * {
    color: #888888;
}

.testimonial-carousel .owl-item.center .testimonial-item i {
    color: var(--primary) !important;
}

.testimonial-carousel .owl-item.center .testimonial-item h6 {
    color: var(--dark) !important;
}


/*** Team ***/

.team-item-it {
  position: relative;
  transition: .5s;
  z-index: 1;
}

.team-item-it::after {
  position: absolute;
  content: "";
  top: 3rem;
  right: 3rem;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0 0 45px rgba(0, 0, 0, .1);
  transition: .5s;
  z-index: -1;
}

.team-item-it:hover::after {
  background: var(--primary);
}

.team-item-it h5,
.team-item-it small {
  transition: .5s;
}

.team-item-it:hover h5,
.team-item-it:hover small {
  color: var(--light);
}

/* construction */

.service-item-construction .service-icon-construction {
  /* margin-top: -50px; */
  /* width: 100px; */
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}

.service-item-construction .service-icon-construction i {
  transition: .2s;
}

.service-item-construction:hover .service-icon i {
  font-size: 60px;
}
/* map portfolio */

.portfolio-map-construction-container {
  display: flex;
  flex-wrap: wrap;
  /* overflow-x: scroll; */
  overflow-y: scroll;
  height: 550px;
  /* scrollbar-width: none;  */
  -ms-overflow-style: none; 
}
.portfolio-map-construction-item {
  margin-bottom: 60px;
  width: 44%;
  display: inline-flex;
  margin-bottom: 12%;

}
/* .portfolio-map-construction-container::-webkit-scrollbar {
  display: none;
} */

.portfolio-map-construction-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.portfolio-map-construction-item:hover .portfolio-btn {
  opacity: 1;
}

.portfolio-map-construction-item:hover .portfolio-construction-box::before {
  left: 0;
  width: 50%;
}

.portfolio-map-construction-item:hover .portfolio-construction-box::after {
  right: 0;
  width: 50%;
}


/* portfolio */

.portfolio-construction-item {
  margin-bottom: 60px;
 
 
}

.portfolio-construction-title {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 120px;
  padding: 0 30px;
  right: 30px;
  left: 30px;
  bottom: -60px;
  background: #FFFFFF;
  z-index: 3;
}

.portfolio-construction-btn {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  margin-top: -60px;
  margin-left: -60px;
  font-size: 120px;
  line-height: 0;
  transition: .1s;
  transition-delay: .3s;
  z-index: 2;
  opacity: 0;
}

.portfolio-construction-item:hover .portfolio-btn {
  opacity: 1;
}

.portfolio-construction-box::before,
.portfolio-construction-box::after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  transition: .5s;
  z-index: 1;
  background: rgba(4, 15, 40, .7);
}

.portfolio-construction-box::before {
  left: 0;
}

.portfolio-construction-box::after {
  right: 0;
}

.portfolio-construction-item:hover .portfolio-construction-box::before {
  left: 0;
  width: 50%;
}

.portfolio-construction-item:hover .portfolio-construction-box::after {
  right: 0;
  width: 50%;
}
/* Testimonial  */
.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(4, 15, 40, .7);
  z-index: 1;
}

@media (max-width: 576px) {
  .carousel-caption h4 {
      font-size: 18px;
      font-weight: 500 !important;
  }

  .carousel-caption h1 {
      font-size: 30px;
      font-weight: 600 !important;
  }
}

/* hotel */

/*** Section Title ***/
.section-title-hotel {
  position: relative;
  display: inline-block;
}

.section-title-hotel::before {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  left: -55px;
  margin-top: -1px;
  background: var(--primary);
}

.section-title-hotel::after {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  right: -55px;
  margin-top: -1px;
  background: var(--primary);
}

.section-title-hotel.text-start::before,
.section-title-hotel.text-end::after {
  display: none;
}

/*** Service ***/
.service-item-hotel {
  height: 320px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0 0 45px rgba(0, 0, 0, .08);
  transition: .5s;
}

.service-item-hotel:hover {
  background: var(--primary);
}

.service-item-hotel .service-hotel-icon {
  margin: 0 auto 30px auto;
  width: 65px;
  height: 65px;
  transition: .5s;
}

.service-item-hotel i,
.service-item-hotel h5,
.service-item-hotel p {
  transition: .5s;
}

.service-item-hotel:hover i,
.service-item-hotel:hover h5,
.service-item-hotel:hover p {
  color: #FFFFFF !important;
}

/*** Youtube Video ***/
.video {
  position: relative;
  height: 100%;
  min-height: 500px;
  background: linear-gradient(rgba(15, 23, 43, .1), rgba(15, 23, 43, .1)), url(../public/img/Hotel/video.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}



.video .btn-play {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 18px 20px 18px 28px;
}

.video .btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video .btn-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  transition: all 200ms;
}

.video .btn-play img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video .btn-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid var(--dark);
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}


@keyframes pulse-border {
  0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
  }

  100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
  }
}

/* Shared styles for modals */
#videoModal, #video1Modal, #video2Modal,#video3Modal,#video4Modal,#video5Modal {
  z-index: 99999;
}

#videoModal .modal-dialog, #video1Modal .modal-dialog, #video2Modal .modal-dialog, #video3Modal .modal-dialog,#video4Modal .modal-dialog ,
#video5Modal .modal-dialog {
  position: relative;
  max-width: 800px;
  margin: 60px auto 0 auto;
}

#videoModal .modal-body, #video1Modal .modal-body, #video2Modal .modal-body,#video3Modal .modal-body,#video4Modal .modal-body {
  position: relative;
  padding: 0px;
}

#videoModal .close, #video1Modal .close, #video2Modal .close,#video3Modal .close,#video4Modal .close ,#video5Modal .close {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0px;
  top: -30px;
  z-index: 999;
  font-size: 30px;
  font-weight: normal;
  color: #FFFFFF;
  background: #000000;
  opacity: 1;
}

/* video construction */
/* .video_construction_videoFirst, .video_construction_video2,.video_construction_video3 {
  position: relative;
  height: 50%;
  min-height: 300px;
  background: linear-gradient( rgba(15, 23, 43, 0.1) rgba(15, 23, 43, 0.1), rgba(15, 23, 43, 0.1)), url('../public/img/Construction/video1.jpg');

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
} */

.video_construction_video1 
{
  position: relative;
  height: 100%;
  min-height: 300px;
  background: linear-gradient(rgba(15, 23, 43, .1), rgba(15, 23, 43, .1)), url(../public/img/Construction/video1.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.video_construction_video2 {
  position: relative;
  height: 100%;
  min-height: 300px;
  background: linear-gradient( rgba(15, 23, 43, 0.1), rgba(15, 23, 43, 0.1)), url(../public/img/Construction/video2.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.video_construction_video3 {
  position: relative;
  height: 50%;
  min-height: 300px;
  background: linear-gradient( rgba(15, 23, 43, 0.1), rgba(15, 23, 43, 0.1)), url(../public/img/Construction/video3.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.video_construction_video4 {
  position: relative;
  height: 50%;
  min-height: 300px;
  background: linear-gradient( rgba(15, 23, 43, 0.1), rgba(15, 23, 43, 0.1)), url(../public/img/Construction/video4.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.video_construction_video5 {
  position: relative;
  height: 50%;
  min-height: 300px;
  background: linear-gradient( rgba(15, 23, 43, 0.1), rgba(15, 23, 43, 0.1)), url(../public/img/Construction/video5.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.video_construction_video1 .btn-play, .video_construction_video2 .btn-play,.video_construction_video3 .btn-play,.video_construction_video4 .btn-play,
.video_construction_video5 .btn-play  {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 18px 20px 18px 28px;
}

.video_construction_video1 .btn-play:before, .video_construction_video2 .btn-play:before,.video_construction_video3 .btn-play:before,.video_construction_video4 .btn-play:before,
.video_construction_video5 .btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video_construction_video1 .btn-play:after, .video_construction_video2 .btn-play:after,.video_construction_video3 .btn-play:after,.video_construction_video4 .btn-play:after ,
.video_construction_video5 .btn-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  transition: all 200ms;
}

.video_construction_video1 .btn-play span, .video_construction_video2 .btn-play span,.video_construction_video3 .btn-play span ,.video_construction_video4 .btn-play span ,
.video_construction_video5 .btn-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid var(--dark);
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}
.video_construction_video1 .btn-play img, 
.video_construction_video2 .btn-play img, 
.video_construction_video3 .btn-play img,
.video_construction_video4 .btn-play img 
.video_construction_video5 .btn-play img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}


/*** It Video ***/
.video_it
 {
  position: relative;
  height: 100%;
  min-height: 500px;
  background: linear-gradient(rgba(15, 23, 43, .1), rgba(15, 23, 43, .1)), url(../public/img/It/video.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}



.video_it .btn-play {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 18px 20px 18px 28px;
}

.video_it .btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video_it .btn-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  transition: all 200ms;
}

.video_it .btn-play img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video_it .btn-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid var(--dark);
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

/* institute */
.video-institute
 {
  position: relative;
  height: 100%;
  min-height: 500px;
  background: linear-gradient(rgba(15, 23, 43, .1), rgba(15, 23, 43, .1)), url(../public/img/Institute/video.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}



.video-institute .btn-play {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 18px 20px 18px 28px;
}

.video-institute .btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-institute .btn-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  transition: all 200ms;
}

.video-institute .btn-play img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video-institute .btn-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid var(--dark);
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}
/*** Testimonial ***/
.testimonial-hotel {
  background: linear-gradient(rgba(15, 23, 43, .7), rgba(15, 23, 43, .7)), url(../public/img/Hotel/carousel-2.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonial-hotel-carousel {
  padding-left: 65px;
  padding-right: 65px;
}

.testimonial-hotel-carousel .testimonial-hotel-item {
  padding: 30px;
}

.testimonial-hotel-carousel .owl-nav {
  position: absolute;
  width: 100%;
  height: 40px;
  top: calc(50% - 20px);
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.testimonial-hotel-carousel .owl-nav .owl-prev,
.testimonial-hotel-carousel .owl-nav .owl-next {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4408e7;
  background: var(--primary);
  border-radius: 2px;
  font-size: 18px;
  transition: .5s;
}

.testimonial-hotel-carousel .owl-nav .owl-prev:hover,
.testimonial-hotel-carousel .owl-nav .owl-next:hover {
  color: var(--primary);
  background: #FFFFFF;
}


 .owl-next {
 
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  background: antiquewhite !important
}
.owl-prev {
 
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  background: antiquewhite !important
}



/* cards */


.ol_header{
  text-align: center;
 
}

ol {
      /* width: min(72rem, 100%); */
      margin-inline: auto;
      display: flex;
      flex-wrap: wrap;
      /* gap: 3.6rem; */
      list-style: none;
      counter-reset: cardnr;
      justify-content: space-evenly;
      /* margin-bottom: 4rem; */
      /* margin-right: 17rem; */
      margin: 5rem 5rem 6rem 4rem;
     
}

li {
  --frontColor:#eff2f6;
  --width: 16rem;
  --inlineP: 0.5rem;
  --borderR: 4rem;
  --arrowW: 2rem;
  --arrowH: 1.5rem;

  counter-increment: cardnr;
  width: calc(var(--width) - var(--inlineP) * 2);
  display: grid;
  grid-template:
    "icon" var(--borderR)
    "title"
    "descr" 1fr;
  margin-inline: var(--inlineP);
  margin-bottom: calc(var(--borderR));
  position: relative;
}
li .icon,
li .title,
li .descr {
  background: var(--frontColor);
  padding-inline: 1rem;
  padding-bottom: 2rem;
}
li .icon,
li .title {
  color: var(--accent-color);
  text-align: center;
  padding-bottom: 0.5rem;
}

li .title,
li .descr {
  filter: drop-shadow(0.125rem 0.125rem 0.075rem rgba(0, 0, 0, 0.25));
}
li .icon {
  grid-area: icon;
  font-size: 2rem;
  display: grid;
  place-items: center;
  border-radius: var(--borderR) 0 0 0;
  position: relative;
}

li .title {
  grid-area: title;
  font-size: 1.1rem;
  font-weight: bold;
}

li .descr {
  grid-area: descr;
  font-size: 0.85rem;
  text-align: left;
}
li .descr::before {
  content: "";
  width: var(--arrowW);
  height: var(--arrowH);
  position: absolute;
  right: 1.5rem;
  top: 100%;
  background: inherit;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
}

li::after {
  content: counter(cardnr, decimal-leading-zero);
  position: absolute;
  z-index: -1;

  left: calc(var(--inlineP) * -1);
  right: calc(var(--inlineP) * -1);
  top: var(--borderR);
  bottom: calc(var(--borderR) * -1);

  display: flex;
  align-items: flex-end;
  background: var(--accent-color);
  background-image: linear-gradient(
    160deg,
    rgba(255, 255, 255, 0.25),
    transparent 25% 75%,
    rgba(0, 0, 0, 0.25)
  );
  border-radius: 0 0 var(--borderR) 0;

  --pad: 1rem;
  padding: var(--pad);
  font-size: calc(var(--borderR) - var(--pad) * 2);
  color: white;
}
li::before {
  content: "";
  position: absolute;
  height: var(--borderR);
  top: calc(100% + var(--borderR) - 2px);
  left: calc(var(--inlineP) * -1);
  right: calc(var(--inlineP) * -1);
  border-radius: 0 var(--borderR) 0 0;

  background-image: linear-gradient(var(--accent-color), transparent 60%);
  opacity: 0.5;
  filter: blur(2px);
}

.credits {
  margin-top: 2rem;
  text-align: right;
}
.credits a {
  color: var(--color);
}


.map {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}



.post-module {
  position: relative;
  z-index: 1;
  display: block;
  background: #FFFFFF;
  min-width: 332px;
  height: 470px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.3s linear 0s;
  margin-bottom: 10%;
}

.post-module:hover,
.hover {
  box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
}

.post-module:hover .thumbnail img,
.hover .thumbnail img {
  transform: scale(1.1);
  opacity: .6;
}

.post-module .thumbnail {
  background: #000000;
  height: 400px;
  overflow: hidden;
}

.post-module .thumbnail .date {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  background: #e74c3c;
  width: 55px;
  height: 55px;
  padding: 12.5px 0;
  border-radius: 100%;
  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  box-sizing: border-box;
}

.post-module .thumbnail .date .day {
  font-size: 18px;
}

.post-module .thumbnail .date .month {
  font-size: 12px;
  text-transform: uppercase;
}

.post-module .thumbnail img {
  display: block;
  width: 120%;
  transition: all 0.3s linear 0s;
}

.post-module .post-content {
  position: absolute;
  bottom: 0;
  background: #FFFFFF;
  width: 100%;
  padding: 38px;
  box-sizing: border-box;
  transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
}

.post-module .post-content .category {
  position: absolute;
  top: -34px;
  left: 0;
  background: #3c7ee7;
  padding: 10px 15px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.post-module .post-content .title {
  margin: 0;
  padding: 0 0 10px;
  color: #333333;
  font-size: 22px;
  font-weight: 700;
}

.post-module .post-content .sub_title {
  margin: 0;
  padding: 0 0 20px;
  color: #e74c3c;
  font-size: 20px;
  font-weight: 400;
}

.post-module .post-content .description {
  display: none;
  color: #666666;
  font-size: 14px;
  line-height: 1.8em;
}

.post-module .post-content .post-meta {
  margin: 30px 0 0;
  color: #999999;
}

.post-module .post-content .post-meta .timestamp {
  margin: 0 16px 0 0;
}

.post-module .post-content .post-meta a {
  color: #999999;
  text-decoration: none;
}

.hover .post-content .description {
  display: block !important;
  height: auto !important;
  opacity: 1 !important;
}

.post_container {
  max-width: 800px;
  min-width: 640px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: 550px;
  -ms-overflow-style: none; 
  /* scrollbar-width: thin; */
  border-radius: 12px;
  border:1px solid gainsboro
}

.post_container:before,
.post_container:after {
  content: '';
  display: block;
  clear: both;
}

.post_container .column {
  width: 50%;
  padding: 0 25px;
  box-sizing: border-box;
  float: left;
}

.post_container .column .demo-title {
  margin: 0 0 15px;
  color: #666666;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.post_container .info {
  width: 300px;
  margin: 50px auto;
  text-align: center;
}

.post_container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.post_container .info span {
  color: #666666;
  font-size: 12px;
}

.post_container .info span a {
  color: #000000;
  text-decoration: none;
}

.post_container .info span .fa {
  color: #e74c3c;
}

.post_container::-webkit-scrollbar {
  width: 6px; /* Adjust the width of the scrollbar */
}

.post_container::-webkit-scrollbar-thumb {
  background-color: #888; /* Change the color of the scrollbar thumb */
  border-radius: 10px; /* Optional: Add round corners to the scrollbar thumb */
}

.post_container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Change the color when hovered */
}

.post_container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Change the background color of the scrollbar track */
  border-radius: 10px; /* Optional: Add round corners to the scrollbar track */
}


.post_container .slider-wrapper{
height: 14rem;
}

/* Add this CSS to hide the thumbs wrapper */
.map_carousel .carousel .thumbs-wrapper {
  display: none;
}



.map_carousel .carousel .carousel-status {
  display: none; /* Hide status indicator */
}
.map_carousel .carousel .control-dots{
  margin: 31px 0;
}



/* single property */


#single-property .fade {
  animation: fadeEffect 1s;
}

@keyframes fadeEffect {
  from {opacity: 0.4;} 
  to {opacity: 1;}
}

#single-property .carousel .slide img {
  width: 100%;
  height: auto;
}

.full-row {
  position: relative;
  width: 100%;
  padding: 80px 0;
}




.property-quantity {
  background-color: #f8f9fa; /* Light gray background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 16px; /* Internal padding */
  margin-bottom: 16px; /* Space below the container */
}

.property-quantity ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.property-quantity li {
  font-size: 16px; /* Font size */
  margin-bottom: 8px; /* Space between items */
  display: flex; /* Align items horizontally */
  justify-content: space-between; /* Space between elements */
  padding: 8px 0; /* Padding within items */
  border-bottom: 1px solid #ddd; /* Divider between items */
}

.property-quantity li:last-child {
  border-bottom: none; /* Remove divider for the last item */
}

.property-quantity .text-secondary {
  font-weight: bold; /* Make the text bold */
  color: #6c757d; /* Secondary color */
}



.accordion-button {
  background-color: #f8f9fa; /* Light gray background */
  color: #6c757d; /* Secondary color */
}

.accordion-button:hover {
  background-color: #007bff; /* Primary color */
  color: #ffffff; /* White text */
}

.accordion-button:focus {
  box-shadow: none; /* Remove default focus shadow */
}

.accordion .collapse {
  background-color: #ffffff; /* White background for the collapse */
  border: 1px solid #ddd; /* Light gray border */
}

.accordion .collapse ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.accordion .collapse ul li {
  font-size: 16px; /* Font size */
  margin-bottom: 8px; /* Space between items */
}

.accordion .collapse ul li span {
  font-weight: bold; /* Bold text */
  color: #6c757d; /* Secondary color */
}

.accordion img {
  margin-top: 16px; /* Space above the image */
  max-width: 100%; /* Make sure the image fits within the container */
  border-radius: 8px; /* Rounded corners for the image */
}


/* property video */

.video-property
 {
  position: relative;
  height: 100%;
  min-height: 500px;
  background: rgba(0, 0, 0, 0) url(../public/img/Construction/video.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}



.video-property .btn-play {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 18px 20px 18px 28px;
}

.video-property .btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-property .btn-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  transition: all 200ms;
}

.video-property .btn-play img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video-property .btn-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid var(--dark);
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}


/* course card */

/* Main heading for inside page */
.inside-page__heading { 
  padding-bottom: 1rem; 
  width: 100%;
}

/* Mixed */

/* For both inside page's main heading and 'view me' text on card front cover */
.inside-page__heading,
.card-front__text-view {
  font-size: 18px;
  font-weight: 700;
  margin-top: 2px;
  text-align: center;
  color: #007bff;


}

/* Front cover */

.card-front__tp { color: #fafbfa; }

/* Back cover */

/* For inside page's body text */
.inside-page__text {
  color: #333;
  margin: 12px;
}


/* Container to hold all cards in one place */
.card-area {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-evenly;
  padding: 7rem;
}

/* Card ============================================*/

/* Area to hold an individual card */
.card-section {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

/* A container to hold the flip card and the inside page */
.card {
  background-color: rgba(0,0,0, .05);
  box-shadow: -.1rem 1.7rem 6.6rem -3.2rem rgba(0,0,0,0.5);
  height: 18rem;
  position: relative;
  transition: all 1s ease;
  width: 18rem;
  margin-top: 12%;
}

/* Flip card - covering both the front and inside front page */

/* An outer container to hold the flip card. This excludes the inside page */
.flip-card {
  height: 18rem;
  perspective: 100rem;
  position: absolute;
  right: 0;
  transition: all 1s ease;
  visibility: hidden;
  width: 18rem;
  z-index: 100;
}

/* The outer container's visibility is set to hidden. This is to make everything within the container NOT set to hidden  */
/* This is done so content in the inside page can be selected */
.flip-card > * {
  visibility: visible;
}

/* An inner container to hold the flip card. This excludes the inside page */
.flip-card__container {
  height: 100%;
  position: absolute;
  right: 0;
  transform-origin: left;
  transform-style: preserve-3d;
  transition: all 1s ease;
  width: 100%;
}

.card-front,
.card-back {
  backface-visibility: hidden;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* Styling for the front side of the flip card */

/* container for the front side */
.card-front {
  background-color: #fafbfa;
  height: 18rem;
  width: 18rem;
}

/* Front side's top section */
.card-front__tp {
  align-items: center;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 57% 90%, 50% 100%, 43% 90%, 0 90%);
  display: flex;
  flex-direction: column;
  height: 15rem;
  justify-content: center;
  padding: .75rem;
}


/* Front card's bottom section */
.card-front__bt {
  align-items: center;
  display: flex;
  justify-content: center;
}

/* Styling for the back side of the flip card */

.card-back {
  background-color: #fafbfa;
  transform: rotateY(180deg);
}


/* Inside page */

.inside-page {
  background-color: #fafbfa;
  box-shadow: inset 20rem 0px 5rem -2.5rem rgba(0,0,0,0.25);
  height: 100%;
  padding: 1rem;
  position: absolute;
  right: 0;
  transition: all 1s ease;
  width: 18rem;
  z-index: 1;
}

.inside-page__container {
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  height: 100%;
  /* text-align: center;  */
  width: 100%;
}

/* Functionality ====================================*/

/* This is to keep the card centered (within its container) when opened */
.card:hover {
  box-shadow:
  -.1rem 1.7rem 6.6rem -3.2rem rgba(0,0,0,0.75);
  width: 30rem;
}

/* When the card is hovered, the flip card container will rotate */
.card:hover .flip-card__container {
  transform: rotateY(-180deg);
}

/* When the card is hovered, the shadow on the inside page will shrink to the left */
.card:hover .inside-page {
  box-shadow: inset 1rem 0px 5rem -2.5rem rgba(0,0,0,0.1);
}

.card-front__image {
  width: 100%;
  height: 18rem;
}
 .card-back img{
  height: 18rem;
 }